import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-minimal',
  templateUrl: './footer-minimal.component.html',
  styles: [],
})
export class FooterMinimalComponent implements OnInit {
  currentYear = new Date().getFullYear();
  constructor() {}

  ngOnInit(): void {}
}
